export default {
  sections: () => {
    return [
      {
        editable: false,
        title: 'Общие сведения',
        params: [
          {
            title: 'АДРЕС',
            key: 'address'
          },
          {
            title: 'ВИД РЕКЛАМНОЙ КОНСТРУКЦИИ',
            key: 'type_advert'
          },
          {
            title: 'НОМЕР РАЗРЕШЕНИЯ',
            key: 'certificate_num'
          },
          {
            title: 'ДАТА НАЧАЛА',
            key: 'nto_date_start'
          },
          {
            title: 'СРОК ОКОНЧАНИЯ РАЗМЕЩЕНИЯ',
            key: 'end_of_placement'
          },
          {
            title: 'ВИД СОБСТВЕННОСТИ',
            key: 'nsi_property_type_name'
          }
        ]
      }
    ];
  }
};
